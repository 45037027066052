import React from 'react'
import Slider from 'react-slick'
import axios from 'axios'
import moment from 'moment'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'

export default class EventList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    moment.updateLocale('pt', {
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    })

    moment.locale('pt')

    axios
      .get(`${process.env.API_URL}/umbraco/api/event/get`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    let photoPlaceHolder = (
      <StaticQuery
        query={graphql`
          query {
            mainlogo: file(relativePath: { eq: "eventplaceholder.png" }) {
              childImageSharp {
                fluid(maxWidth: 560) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Img
            fluid={data.mainlogo.childImageSharp.fluid}
            title="Em Laços"
            alt="Em Laços"
            className="event-photo"
          />
        )}
      />
    )

    let events = '';

    if(this.state.data.length === 0) {
      events =
       <div className="section-padding section-title text-center">
        <p>Sem novos eventos</p>
      </div>
    } else {
      if (this.props.listMode || this.state.data.length < 3) {
        events = this.state.data.map((event, idx) => {
          let desc = event.Description ? event.Description : ''
          let date = event.Confirmed
            ? moment(event.Date).format('DD MMMM YYYY, h:mm')
            : moment(event.Date).format('MMMM')

          let photo
          if (event.Photo) {
            photo = (
              <div className="event-photo">
                <img alt="" src={`${process.env.API_URL}${event.Photo}`} />
              </div>
            )
          } else {
            photo = photoPlaceHolder
          }

          return (
            <div key={idx} className="col-md-6 col-sm-12">
              <div className="single_event">
                {photo}
                <div className="single_event_text">
                  <span>{date}</span>
                  <h4>{event.Name}</h4>
                  <p dangerouslySetInnerHTML={{__html: desc}} />
                </div>
              </div>
            </div>
          )
        });
      } else {
        events = (
          <Slider {...settings}>
            {this.state.data.map((event, idx) => {
              let desc = event.Description ? event.Description : ''
              let date = event.Confirmed
                ? moment(event.Date).format('DD MMMM YYYY, h:mm')
                : moment(event.Date).format('MMMM')

              let photo
              if (event.Photo) {
                photo = (
                  <div className="event-photo">
                    <img alt="" src={`${process.env.API_URL}${event.Photo}`} />
                  </div>
                )
              } else {
                photo = photoPlaceHolder
              }

              return (
                <div key={idx} className="item active">
                  <div className="col-md-12">
                    <div className="single_event">
                      {photo}
                      <div className="single_event_text">
                        <span>{date}</span>
                        <h4>{event.Name}</h4>
                        <p dangerouslySetInnerHTML={{__html: desc}} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        )
      }
    }

    let header;

    if (this.props.showHeader) {
      header = (
        <div className="section-title text-center wow zoomIn">
          <h2>Os Nossos Eventos</h2>
          <span />
        </div>
      )
    }

    return (
      <section id="event" className="our_event section-padding mb-15">
        <div className="container">
          <div className="row">
            {header}
            {events}
          </div>
        </div>
      </section>
    )
  }
}
